import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://formsubmit.co/ajax/admin@dataflows.com.au', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus('Form submitted successfully!');
        console.log(data);
      })
      .catch((error) => {
        setStatus('Form submission failed. Please try again.');
        console.log(error);
      });
  };

  return (
    <div style={{ display: 'flex', position: 'relative', alignItems: 'center', flexDirection: 'column' }}>
      <h1 style={{ marginBottom: '20px', fontSize: '24px' }}>Contact Us</h1>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '10px',
          width: '100%', // Adjust as necessary
        }}
        onSubmit={handleSubmit}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            name="name"
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <label htmlFor="message">Your Message</label>
          <textarea
            placeholder="Your Message"
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '100%',
              minHeight: '100px',
            }}
            name="message"
            rows="10"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div style={{ alignSelf: 'flex-start', marginTop: '10px' }}>
          <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#000', color: '#fff', border: 'none', borderRadius: '5px' }}>
            Submit Form
          </button>
        </div>
        <p>{status}</p>
      </form>
    </div>
  );
};

export default ContactForm;

